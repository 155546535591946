import { graphql } from "gatsby";
import React from "react";
import Layout from "~/components/Layout";
import useCategories from "~/hooks/useCategories";

import FlexibleContent from "~/components/FlexibleContent";
import Resources from "~/components/flexible/Resources";
import { WpPage_Page_FlexibleContent } from "~/_generated/types";
import { useSearch } from "~/hooks/useSearch";
import SearchResults from "~/components/global/SearchResults";
import useBlogPosts from "~/hooks/useBlogPosts";
import Seo from "gatsby-plugin-wpgraphql-seo";

export default function resouurcesPage({
  data: { wpPage, wp, siteSearchIndex },
}: {
  data: { wpPage: { page: { flexibleContent: WpPage_Page_FlexibleContent } } };
}) {
  const { nodes } = useCategories();
  const categories = nodes;

  const [query, setQuery] = React.useState("");

  const { allWpPost } = useBlogPosts();

  const results = useSearch(siteSearchIndex.index, query);

  const resourceResults = results
    .filter(({ nodeType }) => nodeType === "Post")
    .map(({ slug }) => allWpPost.nodes.find((post) => post.slug === slug));

  return (
    <Layout
      wp={wp}
      resourcesHeader
      activeSearchQuery={query}
      setQuery={setQuery}
    >
      <Seo post={wpPage} />
      <div className="pt-[200px]">
        {query?.trim().length > 0 ? (
          <SearchResults results={resourceResults} query={query} />
        ) : (
          categories.map((category, i) => (
            <Resources
              {...category}
              featured
              last={i === categories.length - 1}
            />
          ))
        )}
        <FlexibleContent content={wpPage?.page?.flexibleContent} />
      </div>
    </Layout>
  );
}

export const pageQuery = graphql`
  query Resources($id: String!) {
    wpPage(id: { eq: $id }) {
      ...SEO
      ...GeneratedWpPage
    }
    wp {
      ...GeneratedWp
    }

    siteSearchIndex {
      index
    }
  }
`;
